import { formatNumber } from "@tamarack/shared/number";
import { twMerge } from "tailwind-merge";
import Button from "./Button";
import ChevronBackIcon from "./icons/ChevronBackIcon";
import ChevronForwardIcon from "./icons/ChevronForwardIcon";

export const DEFAULT_PAGE_SIZE = 20;

type TablePaginationProps = {
  /**
   * The current page of the table using this component
   */
  page: number;
  /**
   * Alert the parent component that the page has changed
   */
  onPageChange: (page: number) => void;
  className?: string;
  /**
   * The number of rows per page
   * @default 20
   */
  rowsPerPage?: number;
  /**
   * Total number of items in the database
   */
  count: number;
  /**
   * Hide the item counts
   * @default false
   */
  hideCounts?: boolean;
};

export default function TablePagination({
  count,
  className,
  page,
  onPageChange,
  rowsPerPage = DEFAULT_PAGE_SIZE,
  hideCounts,
}: TablePaginationProps) {
  /**
   * Hide pagination if there are no items to render
   */
  if (count === 0) {
    return null;
  }

  const startRowCount = page * rowsPerPage + 1;
  const endRowCount = page * rowsPerPage + rowsPerPage;
  const totalPages = Math.ceil(count / rowsPerPage);
  const hasPrevious = page > 0;
  const hasNext = page < totalPages - 1;
  const lastRowNumber = Math.min(endRowCount, count);
  const firstRowNumber = lastRowNumber == 0 ? "0" : startRowCount;

  return (
    <nav role="navigation" className="relative">
      <div
        className={twMerge("flex w-full items-center justify-end px-d py-1 text-[13px]", className)}
      >
        {hideCounts ? null : (
          <span className="pr-2">
            <strong className="font-bold">
              {formatNumber(firstRowNumber)}–{lastRowNumber}
            </strong>{" "}
            of {formatNumber(count)}
          </span>
        )}

        <Button
          disabled={!hasPrevious}
          disabledDescription="You're already on the first page"
          variant="text"
          onClick={() => onPageChange(0)}
          size="small"
          withTooltip="First page"
        >
          <ChevronBackIcon size={14} className="absolute right-[14px]" />
          <ChevronBackIcon size={14} className="relative right-[-3px]" />
        </Button>

        <Button
          disabled={!hasPrevious}
          disabledDescription="There are no more previous pages"
          variant="text"
          onClick={() => onPageChange(page - 1)}
          size="small"
          withTooltip="Previous page"
        >
          <ChevronBackIcon size={14} />
          Previous
        </Button>

        <Button
          disabled={!hasNext}
          variant="text"
          onClick={() => onPageChange(page + 1)}
          disabledDescription="There are no more pages"
          withTooltip="Next page"
          size="small"
        >
          Next
          <ChevronForwardIcon size={14} />
        </Button>

        <Button
          disabled={!hasNext}
          variant="text"
          onClick={() => onPageChange(totalPages - 1)}
          disabledDescription="You're already on the last page"
          size="small"
          className="relative"
          withTooltip="Last page"
        >
          <ChevronForwardIcon size={14} className="relative left-[-3px]" />
          <ChevronForwardIcon size={14} className="absolute left-[14px]" />
        </Button>
      </div>
    </nav>
  );
}
