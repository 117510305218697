import { Pageable } from "@tamarack/shared/Pageable";

export type AppUrlParams = URLSearchParams | Pageable;

/**
 * Partially implements the URL class to add a toString method that returns the pathname and search params as
 * a relative url to the application's host.
 *
 * It doesn't extend the URL class because Remix's url handling doesn't like the URL class.
 *
 * @example
 * const url = new AppUrl('/sales-orders')
 * url.mergeSearchParams(new URLSearchParams('a=1&b=1'), new URLSearchParams('c=2&d=1'))
 *
 * const childUrl = url.childRoute('/new')
 */
export class AppUrl {
  static fromAppUrl(url: AppUrl, defaultParams?: AppUrlParams) {
    return new AppUrl(url.toString(), defaultParams);
  }

  static canParse(pathname: AppUrl | string) {
    return URL.canParse(new AppUrl(pathname).toString());
  }

  pathname = "";
  hash = "";
  search = "";
  readonly searchParams = new URLSearchParams();

  constructor(pathname: string | AppUrl, defaultParams?: AppUrlParams) {
    if (pathname instanceof AppUrl) {
      return AppUrl.fromAppUrl(pathname, defaultParams);
    }

    /**
     * The host doesn't actually matter in our application, so it can be anything valid
     */
    const url = new URL(pathname, "http://localhost");

    this.pathname = url.pathname;
    this.hash = url.hash;
    this.searchParams = new URLSearchParams(url.searchParams);
    this.search = url.search;

    this.withParams(defaultParams);
  }

  toString() {
    return `${this.pathname}${this.search}`;
  }

  syncSearch() {
    const paramsString = this.searchParams.toString();
    this.search = paramsString === "" ? "" : `?${paramsString}`;

    return this;
  }

  resetSearchParams() {
    for (const param of this.searchParams) {
      for (const [key] of param) {
        this.searchParams.delete(key);
      }
    }

    return this.syncSearch();
  }

  mergeSearchParams(params?: URLSearchParams) {
    if (!params) {
      return this;
    }

    for (const [key, value] of params) {
      this.searchParams.set(key, value);
    }

    return this.syncSearch();
  }

  mergeSearchParamsFromObject(
    params?: Record<string, string | string[] | number | number[] | undefined | null>
  ) {
    if (!params) {
      return this;
    }

    for (const [key, value] of Object.entries(params)) {
      /**
       * Multiple values for the same key
       */
      if (Array.isArray(value)) {
        for (const v of value) {
          if (v !== undefined && v !== null) {
            this.searchParams.append(key, v.toString());
          }
        }
      } else if (value !== undefined && value !== null) {
        /**
         * Single value for the same key
         */
        this.searchParams.set(key, value.toString());
      }
    }

    return this.syncSearch();
  }

  appendSearchParams(params?: URLSearchParams) {
    if (!params) {
      return this;
    }

    for (const [key, value] of params) {
      this.searchParams.append(key, value);
    }

    return this.syncSearch();
  }

  appendSearchParam(key: string, value?: string) {
    if (value) {
      this.searchParams.append(key, value);
    }

    return this.syncSearch();
  }

  appendSearchParamsFromObject(
    params?: Record<string, string | string[] | number | number[] | undefined | null>
  ) {
    if (!params) {
      return this;
    }

    for (const [key, value] of Object.entries(params)) {
      /**
       * Multiple values for the same key
       */
      if (Array.isArray(value)) {
        for (const v of value) {
          if (v !== undefined && v !== null) {
            this.searchParams.append(key, v.toString());
          }
        }
      } else if (value !== undefined && value !== null) {
        /**
         * Single value for the same key
         */
        this.searchParams.append(key, value.toString());
      }
    }

    return this.syncSearch();
  }

  withParams(params?: AppUrlParams) {
    if (params instanceof Pageable) {
      this.withPageable(params);
    } else if (params instanceof URLSearchParams) {
      this.mergeSearchParams(params);
    }

    return this;
  }

  withPageable(pageable: Pageable) {
    return this.mergeSearchParamsFromObject(pageable.toJSON());
  }

  matchesPathname(pathname: string) {
    return this.pathname === pathname;
  }

  matches(appUrl: AppUrl | string) {
    return this.toString() === appUrl.toString();
  }

  childRoute(pathname: string, defaultParams?: AppUrlParams) {
    const childPathname = pathname.startsWith("/") ? pathname : `/${pathname}`;

    this.withParams(defaultParams);

    return new AppUrl(`${this.pathname}${childPathname}`, this.searchParams);
  }
}
