import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function ChevronForwardIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <>
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <polygon fill="currentColor" points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
          </g>
        </>
      }
    />
  );
}
