import Big from "big.js";

export const formatNumber = (number: number | string) => {
  return new Intl.NumberFormat().format(+number);
};

export const formatNumberWithDecimals = (number: number | string, numberOfDecimalPlaces = 3) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: numberOfDecimalPlaces,
  }).format(+number);
};

export const formatNumberWithDecimalsWithoutCommas = (
  number: number | string,
  numberOfDecimalPlaces = 3
) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: numberOfDecimalPlaces,
    useGrouping: false,
  }).format(+number);
};

export const formatMbf = (mbf: number | string) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 3,
  }).format(+mbf);
};

const _formatCurrency = (options: Intl.NumberFormatOptions, number?: number | string) => {
  if (number === undefined || number === null) {
    return "";
  }

  // Forcing US currency here otherwise there are issues with Canadian/US dollar rendering where this would prepend a "US" before the number.
  return new Intl.NumberFormat("en-US", options).format(+number);
};

export const formatCurrency = (number?: number | string) => {
  return _formatCurrency({ style: "currency", currency: "USD" }, number);
};

export const formatWholeNumberCurrency = (number?: number | string) => {
  return _formatCurrency({ style: "currency", currency: "USD", maximumFractionDigits: 0 }, number);
};

/**
 *
 * @param number in decimal format
 * @returns number as a percentage
 */
export const formatPercentage = (number: number | string) => {
  if (number === undefined) {
    return "";
  }

  return new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2 }).format(
    +number
  );
};

/**
 * Add numbers and avoid floating point errors
 */
export const add = (...numbers: (number | string)[]) => {
  const [firstNumber, ...otherNumbers] = numbers;
  return otherNumbers.reduce((acc, val) => acc.add(val), Big(firstNumber)).toNumber();
};

/**
 * Subtract numbers and avoid floating point errors
 */
export const subtract = (...numbers: (number | string)[]) => {
  const [firstNumber, ...otherNumbers] = numbers;

  return otherNumbers.reduce((acc, val) => acc.sub(val), Big(firstNumber)).toNumber();
};

/**
 * Multiply numbers and avoid floating point errors
 */
export const multiply = (...numbers: (number | string)[]) => {
  const [firstNumber, ...otherNumbers] = numbers;

  return otherNumbers.reduce((acc, val) => acc.mul(val), Big(firstNumber)).toNumber();
};

export const divide = (...numbers: (number | string)[]) => {
  const [firstNumber, ...otherNumbers] = numbers;

  return otherNumbers.reduce((acc, val) => acc.div(val), Big(firstNumber)).toNumber();
};
